import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { TimeZone } from '../../shared/models/dashabordAlertData';
import { EventDetails } from '../../shared/models/eventDetails';
import { TIMEZONE } from '../../shared/constants/timezone-constant';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { AlertFilter } from '../../shared/models/alertFilter';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { LINECHARTOPTIONS } from '../../shared/constants/chart-constant';
import { EVENTCOLORS } from '../../shared/constants/camera-profile-constant';
import { GlobalViewService } from '../../shared/services/global-view.service';
import { EventStatusItems, GlobalChartSummary, GlobalSafety, WorkFlow } from '../../shared/models/global-view';
import { Event } from '../../shared/models/eventChart';
import { DatePipe } from '@angular/common';
import { riskStatusWorkflow } from '../../shared/models/dashabordEventDetails';
import { RISKCHARTCONSTANT, WORKFLOWEVENT, DOWNLOADFORMAT } from '../../shared/constants/global-view-constant';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DashboardService } from '../../shared/services/dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';

noData(Highcharts);
@Component({
  selector: 'app-global-charts',
  templateUrl: './global-charts.component.html',
  styleUrls: ['./global-charts.component.scss'],
})
export class GlobalChartsComponent implements OnInit, OnDestroy {
  @ViewChild('graphChartContainer', { static: false }) graphChartContainer!: ElementRef;
  @ViewChild(MatMenuTrigger)
  menuTrigger!: MatMenuTrigger;
  @Input() workflow: WorkFlow[];
  public dateRanges = DashboardConstants.dateRanges;
  public popOverCameraDetails: EventDetails | null;
  public workflowList: string[] = [];
  public timezoneList = TIMEZONE;
  public currentZone = '';
  public currentZoneArea = '';
  public currentZoneDetails!: TimeZone;
  public timeZoneChange = false;
  public summaryLoader = false;
  public alertsLoader = false;
  public riskLoader = false;
  public alertFilter: AlertFilter = JSON.parse(JSON.stringify(DashboardConstants.defaultFilter));
  public selectedPopOverIndex = -1;
  public Highcharts = Highcharts;
  public totalEvents = 0;
  public eventLineChartOption: Highcharts.Options;
  public updateEventLineChart: boolean;
  public chartTimeLine = '';
  public startDate: Date;
  public endDate: Date;
  public riskData: EventStatusItems[] = [];
  public filterData: { [key: string]: string[] };
  public validCount?: number;
  public invalidCount?: number;
  public notReviewedCount?: number;
  public excludeCount?: number;
  public eventRiskList: any[] = [];
  public totalRedZoneEvents = 0;
  public totalPpeEvents = 0;
  public totalCatwalkEvents = 0;
  public totalPiperackEvents = 0;
  public riskDataWfResponse: riskStatusWorkflow[];
  public riskDataWfList: riskStatusWorkflow[];
  public selectedWf = RISKCHARTCONSTANT.REDZONE.name;
  public enableRedzoneRiskStatus = false;
  public enablePpeRiskStatus = false;
  public enableCatwalkRiskStatus = false;
  public enablePiperackRiskStatus = false;
  public isFullScreen = false;
  public enabledRiskCategories: (string | undefined)[];
  public isAdmin = false;
  public isRiskStatusEmpty = false;
  public isMenuOpen = false;
  private enabledWorkflow? = '';
  private riskArr: riskStatusWorkflow[];
  private showAllEvents: string;
  private destroyed = new Subject();
  private timeZone: string;
  private summaryState = new BehaviorSubject(true);
  private summaryState$ = this.summaryState.asObservable();
  private safetyChart: riskStatusWorkflow[];

  constructor(
    private messageService: MessageService,
    private cameraProfileService: CameraProfileService,
    private timeZoneService: TimeZoneService,
    private globalViewService: GlobalViewService,
    private dashboardService: DashboardService,
    private datePipe: DatePipe
  ) {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((timeZoneDetails: TimeZone) => {
          this.currentZoneArea = timeZoneDetails?.label;
          this.currentZoneDetails = timeZoneDetails;
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.summaryLoader = true;
    this.alertsLoader = true;
    this.riskLoader = true;
    this.updateEventLineChart = false;
    this.timeZone = sessionStorage.getItem('timezone') ?? '';
    this.isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.eventLineChartOption = JSON.parse(JSON.stringify(LINECHARTOPTIONS));
    document.addEventListener('fullscreenchange', () => {
      this.isFullScreen = !!document.fullscreenElement;
    });
  }

  public setViolationChartData(data: { [keys: string]: number }): void {
    if (data) {
      this.validCount = data.valid;
      this.invalidCount = data.invalid;
      this.notReviewedCount = data.notReviewed;
      this.excludeCount = data.excluded;
    }
    this.alertsLoader = false;
  }

  public onTimezoneChange(): void {
    this.timeZoneService.setSelectedTimeZone(this.currentZoneDetails);
    this.timeZoneChange = true;
    this.triggerData();
    this.currentZone = this.timeZoneService.getTimeZone();
    this.cameraProfileService.getEventData(false);
    this.setChartTimeline(this.startDate, this.endDate);
  }

  public setChartTimeline(startDate: Date, endDate: Date): void {
    this.chartTimeLine =
      this.datePipe.transform(startDate, 'medium', this.currentZoneDetails.timezone) +
      ' - ' +
      this.datePipe.transform(endDate, 'medium', this.currentZoneDetails.timezone);
  }

  public switchDays(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.triggerData();
  }

  public workFlowEventStatusChange(filteredData: { [keys: string]: string[] }): void {
    this.filterData = filteredData;
    this.triggerData();
  }

  public setFirstInitialization(startDate: Date, endDate: Date, filteredData: { [keys: string]: string[] }): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.filterData = filteredData;
    this.getSummaryData();
  }

  public getSummaryData(): void {
    this.summaryState$
      .pipe(
        switchMap(() =>
          this.cameraProfileService.eventData$.pipe(
            tap(() => {
              this.summaryLoader = true;
              this.alertsLoader = true;
              this.showAllEvents = sessionStorage.getItem('showAllEvents') || '';
              this.timeZone = sessionStorage.getItem('timezone') ?? '';
            }, takeUntil(this.destroyed)),
            switchMap(() =>
              this.globalViewService
                .getEventChart(this.startDate.toISOString(), this.endDate.toISOString(), this.timeZone, this.filterData, this.showAllEvents)
                .pipe(
                  catchError(() => {
                    this.messageService.add({
                      severity: SlbSeverity.Error,
                      summary: DashboardConstants.dashabordChartAPIError,
                      closable: true,
                      sticky: true,
                    });
                    this.summaryLoader = false;
                    this.alertsLoader = false;

                    return of<GlobalChartSummary>({} as GlobalChartSummary);
                  }),
                  tap((data: GlobalChartSummary) => {
                    if (data && data.eventStatus && data.eventTrend && data.eventAtRisk) {
                      this.createSafety(data?.chartEventDetails);
                      this.setViolationChartData(data?.eventStatus);
                      this.setEventLineChartData(data?.eventTrend);
                      this.createRiskStatusChart(data?.eventAtRisk);
                    } else {
                      this.initChartForNoResp();
                    }
                  }),
                  takeUntil(this.destroyed)
                )
            ),
            takeUntil(this.destroyed)
          )
        )
      )
      .subscribe();
  }

  public riskDataListWorkflow(riskDataList: riskStatusWorkflow[]): void {
    this.riskDataWfResponse = riskDataList;
  }

  public selectedWorkFlowList(workflow: (string | undefined)[], riskCategories: (string | undefined)[]): void {
    this.enabledRiskCategories = riskCategories;
    this.enabledWorkflow = workflow[0];
    this.enableRedzoneRiskStatus = false;
    this.enablePpeRiskStatus = false;
    this.enableCatwalkRiskStatus = false;
    this.enablePiperackRiskStatus = false;
    if (workflow[0] === WORKFLOWEVENT.ALL) {
      this.enableRedzoneRiskStatus = true;
      this.enablePpeRiskStatus = true;
      this.enableCatwalkRiskStatus = true;
      this.enablePiperackRiskStatus = true;
    } else {
      workflow.forEach(wf => {
        this.enableRedzoneRiskStatus = wf === RISKCHARTCONSTANT.REDZONE.name ? true : this.enableRedzoneRiskStatus;
        this.enablePpeRiskStatus = wf === RISKCHARTCONSTANT.PPE.name ? true : this.enablePpeRiskStatus;
        this.enableCatwalkRiskStatus = wf === RISKCHARTCONSTANT.CATWALK.name ? true : this.enableCatwalkRiskStatus;
        this.enablePiperackRiskStatus = wf === RISKCHARTCONSTANT.PIPERACK.name ? true : this.enablePiperackRiskStatus;
      });
    }
  }

  public createRiskStatusChart(chartData: { [keys: string]: number }): void {
    const options: { name: string; y: number }[] = [];
    Object.keys(chartData).map((key: string) => {
      if (key !== 'total' && chartData[key] !== null) {
        options.push({
          name: key,
          y: chartData[key],
        });
      }
    });
    if (this.enabledRiskCategories[0] === WORKFLOWEVENT.ALL) {
      options.forEach(op => this.enabledRiskCategories.push(op.name));
    }
    this.riskArr = this.riskDataWfResponse.filter(
      rd =>
        rd.riskCategory.toLowerCase() ===
        this.enabledRiskCategories.find(rc => rc?.toLowerCase() === rd.riskCategory.toLowerCase())?.toLowerCase()
    );
    this.riskArr.forEach(rd => {
      rd.value = options.find(op => op.name.toLowerCase() === rd.riskCategory?.toLowerCase())?.y;
      rd.name = this.formatLabel(rd.riskCategory);
    });
    this.riskArr = [...this.riskArr, ...this.safetyChart];
    this.riskDataWfList = this.riskArr;
    if (this.enabledWorkflow) {
      this.onRiskEventClick(this.enabledWorkflow);
    }
    this.riskLoader = false;
  }

  public onRiskEventClick(workflow: string): void {
    if (workflow === WORKFLOWEVENT.ALL) {
      workflow = RISKCHARTCONSTANT.REDZONE.name;
    }
    this.selectedWf = workflow;
    this.riskDataWfList = this.riskArr;
    this.riskDataWfList = this.riskDataWfList.filter(rd => rd.workflow === workflow);
    this.isRiskStatusEmpty = !this.riskDataWfList.length ? true : false;
  }

  public downloadAsPngJpeg(type: string): void {
    const element = document.getElementById('graph_chart');
    if (!element) {
      return;
    }

    html2canvas(element).then(canvas => {
      const link = document.createElement('a');
      if (type === DOWNLOADFORMAT.PNG) {
        link.href = canvas.toDataURL('image/png');
        link.download = 'EventsChart-' + this.formatCurrentDate() + '.png';
      } else if (type === DOWNLOADFORMAT.JPEG) {
        link.href = canvas.toDataURL('image/jpeg', 1);
        link.download = 'EventsChart-' + this.formatCurrentDate() + '.jpeg';
      }
      link.click();
    });
  }

  public downloadAsPdf(): void {
    const element = document.getElementById('graph_chart');
    if (!element) {
      return;
    }

    html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('EventsChart-' + this.formatCurrentDate() + '.pdf');
    });
  }

  public toggleMenu(): void {
    this.isMenuOpen = true;
  }

  public onMenuClose(): void {
    this.isMenuOpen = false;
  }

  public closeMenu(): void {
    this.menuTrigger.closeMenu();
    this.isMenuOpen = false;
  }

  public formatCurrentDate(): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(new Date(), DOWNLOADFORMAT.TIMEFORMATDOWNLOAD);

    return formattedDate || '';
  }

  public toggleFullScreen(): void {
    const element = this.graphChartContainer.nativeElement;
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        this.isFullScreen = true;
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.isFullScreen = false;
      }
    }
  }

  public exitFullScreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    this.isFullScreen = false;
  }

  public truncateTabName(input?: string): string {
    if (input) {
      return input.length > 30 ? input.substring(0, 27) + '...' : input;
    }

    return '';
  }

  public setLabel(workflow: string): string {
    return this.dashboardService.setWorkflowLabel(workflow);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private triggerData(): void {
    if (this.filterData) {
      const isFilter = Object.keys(this.filterData).filter(data => this.filterData[data]?.length);
      if (!isFilter?.length) {
        this.totalEvents = 0;
      }
    }
  }

  private setEventLineChartData(chartData: Event[]): void {
    const eventSeries: Highcharts.SeriesOptionsType[] = [];
    this.totalRedZoneEvents = 0;
    this.totalPpeEvents = 0;
    this.totalCatwalkEvents = 0;
    this.totalPiperackEvents = 0;
    let totalEvents = 0;
    const eventType = DashboardConstants.EVENTTYPELINECHART.reverse();
    eventType.map(type => {
      const seriesData: Highcharts.PointOptionsObject[] = [];
      chartData?.map(e => {
        const eventByType = e.eventTotal.find((ev: { eventType: string }) => this.checkType(type, ev.eventType));
        if (eventByType) {
          seriesData.push({
            y: eventByType.total,
            x: new Date(e.date + ' 00:00').getTime(),
          } as Highcharts.PointOptionsObject);
          switch (eventByType.eventType) {
            case RISKCHARTCONSTANT.REDZONE.name:
              this.totalRedZoneEvents += eventByType.total;
              break;
            case RISKCHARTCONSTANT.PPE.name:
              this.totalPpeEvents += eventByType.total;
              break;
            case RISKCHARTCONSTANT.CATWALK.name:
              this.totalCatwalkEvents += eventByType.total;
              break;
            case RISKCHARTCONSTANT.PIPERACK.name:
              this.totalPiperackEvents += eventByType.total;
              break;
            default:
              break;
          }
          totalEvents += eventByType.total;
        }
      });
      if (seriesData.length > 0) {
        switch (type) {
          case RISKCHARTCONSTANT.REDZONE.label:
            this.setLineChartPlotData(eventSeries, this.totalRedZoneEvents, type, seriesData);
            break;
          case RISKCHARTCONSTANT.PPE.label:
            this.setLineChartPlotData(eventSeries, this.totalPpeEvents, type, seriesData);
            break;
          case RISKCHARTCONSTANT.PIPERACK.label:
            this.setLineChartPlotData(eventSeries, this.totalPiperackEvents, type, seriesData);
            break;
          case RISKCHARTCONSTANT.CATWALK.label:
            this.setLineChartPlotData(eventSeries, this.totalCatwalkEvents, type, seriesData);
            break;
          default:
            break;
        }
      }
    });
    this.eventLineChartOption.series = eventSeries;
    this.eventLineChartOption.tooltip = {
      pointFormatter(): string {
        const label = (this.series.name || '').split(' - ')[0];
        const colorMarker = `<span style="color:${this.color};">●</span> `;

        return `${colorMarker}<b>${label}:</b> ${this.y}<br>`;
      },
      distance: 20,
      outside: false,
      shared: true,
    };
    this.totalEvents = totalEvents;
    this.updateEventLineChart = true;
    this.summaryLoader = false;
  }

  private setLineChartPlotData(
    eventSeries: Highcharts.SeriesOptionsType[],
    eventCount: number,
    type: string,
    seriesData: Highcharts.PointOptionsObject[]
  ): void {
    eventSeries.push({
      lineWidth: 2,
      type: 'line',
      name: type + ' - ' + eventCount,
      color: EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0]
        ? EVENTCOLORS.filter(tag => this.checkType(type, tag.name))[0].color
        : '',
      data: seriesData,
      visible: true,
    } as Highcharts.SeriesOptionsType);
  }

  private checkType(type: string, eventType: string): boolean {
    return type.split(' ').join('').toLowerCase().includes(eventType.toLowerCase());
  }

  private formatLabel(key: string): string {
    key.replace(key[0], key[0].toUpperCase());
    const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
    if (splitBasedonUpperCase?.length) {
      return splitBasedonUpperCase.join(' ');
    }

    return '';
  }

  private initChartForNoResp(): void {
    this.totalEvents = 0;
    this.riskDataWfList = [];
    this.summaryLoader = false;
    this.riskLoader = false;
    this.alertsLoader = false;
  }

  private createSafety(data: GlobalSafety[]): void {
    this.safetyChart = [];
    data.forEach(rd => {
      if (this.workflow.find(wf => wf.id === rd.id)?.name) {
        this.safetyChart.push({
          riskCategory: rd.label,
          workflow: this.workflow.find(wf => wf.id === rd.id)?.name,
          value: parseInt(rd.value, 10),
          name: this.formatValue(rd.label),
        });
      }
    });
  }

  private formatValue(key: string): string {
    key.replace(key[0], key[0].toUpperCase());
    const splitBasedonUpperCase = key.replace(key[0], key[0].toUpperCase()).match(/[A-Z][a-z]+/g);
    if (splitBasedonUpperCase?.length) {
      return splitBasedonUpperCase.join(' ');
    }

    return '';
  }
}
