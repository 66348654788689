<div *ngIf="!isLoading; else loader">
  <p *ngIf="dataSource && dataSource.data && dataSource.data.length === 0; else showTable" class="no-data"></p>
  <ng-template #showTable>
    <div class="horizontal-scroll-except-first-column">
      <table
        mat-table
        *ngIf="dataSource && dataSource.data && dataSource.data.length > 0"
        matSort
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSortActive="timestamp"
        matSortDirection="desc">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null; checkboxChange()"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_row_selection_' + (i + 1) }}">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null; checkboxChange()"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Event time</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'vi_camera_profile_alerts_table' + '_event_time_' + (i + 1) }}"
            class="first-col">
            <a (click)="onImageClick(element, i); element.isClicked = true">
              {{ element.timestamp | date : 'MMM d, y, h:mm:ss a' : currentZone }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="gatewayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_gateway' + (i + 1) }}">
            {{ element.gatewayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="equipmentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Camera</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.equipmentName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="confidence">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Confidence(%)</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.confidence }}
          </td>
        </ng-container>

        <ng-container matColumnDef="BPOS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ bposHeader }}</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.BPOS }}
          </td>
        </ng-container>

        <ng-container matColumnDef="BVEL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ bvelHeader }}</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.BVEL }}
          </td>
        </ng-container>

        <ng-container matColumnDef="RPM">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ rpmHeader }}</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.RPM }}
          </td>
        </ng-container>

        <ng-container matColumnDef="SPPA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ sppaHeader }}</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_camera' + (i + 1) }}">
            {{ element.SPPA }}
          </td>
        </ng-container>

        <!-- Event Column -->
        <ng-container matColumnDef="peopleInsideRedZone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            id="{{ 'vi_camera_profile_alerts_table' + '_people_inside_redzone' + (i + 1) }}">
            <p class="camera-info">
              <img src="{{ element?.icon }}" class="card-notations bg-image" id="vi_camera_profile_alerts_grid_card_rig_icon" />
              <span *ngIf="element.label !== ''">{{ element?.label }}: </span>
              <span class="value" *ngIf="element?.rigState" matTooltip="{{ element.rigState }}">{{ element.rigState }}</span>
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_reviewed' + (i + 1) }}">
            <span *ngIf="element.category === 'VALID'">
              <div class="chip valid-chip"><mat-icon svgIcon="check" class="icon valid-check-icon"></mat-icon>Valid</div>
            </span>
            <span *ngIf="element.category === 'INVALID'">
              <div class="chip invalid-chip"><mat-icon svgIcon="check" class="icon invalid-check-icon"></mat-icon>Invalid</div>
            </span>
            <span *ngIf="element.category === 'EXCLUDE'">
              <div class="chip exclude-chip"><mat-icon svgIcon="remove" class="icon exclude-remove-icon"></mat-icon>Excluded</div>
            </span>
            <span *ngIf="element.category === 'NONE'">
              <div class="chip notReviewed-chip"><mat-icon svgIcon="close" class="icon close-icon"></mat-icon>Not reviewed</div>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastComment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_reviewed' + (i + 1) }}">
            <span class="last-comment">{{ element.lastComment }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Áction</th>
          <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_image' + (i + 1) }}">
            <mat-icon
              svgIcon="preview"
              class="image-icon cursor-pointer"
              (click)="onImageClick(element, i); element.isClicked = true"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ highlight: row.isClicked }"></tr>
      </table>
    </div>
  </ng-template>
</div>
<mat-paginator
  id="vi_camera_profile_alerts_table_pagination"
  [pageSizeOptions]="[10, 20, 30, 50, 100]"
  [length]="totalNoRecords"
  #paginator
  showLabel=" false"
  showFirstLastButtons
  (page)="pageEvent($event)"
  class="camera-profile-table-pagination">
</mat-paginator>
<mat-drawer #drawer class="example-sidenav" position="end" [opened]="isOpen" disableClose>
  <app-event-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event)"
    (updateParentData)="updateParentData($event)"
    (closeSideInfo)="drawer.close(); popOverCameraDetails = null; isOpen = false"></app-event-popover>
</mat-drawer>
<ng-template #loader>
  <div class="spinner">
    <app-spinner></app-spinner>
  </div>
</ng-template>
